<template>
  <div>
     <center>
         <br v-if="isMobile">
        <b-row class="justify-content-center">
            <b-col cols="12" md="4" v-if="isMobile">
                <div class="text-center mt-4" id="countdown2" >
                    <h2 :style="isMobile ? 'cursor:pointer; font-size:30px; font-family:Saira Stencil One, cursive;' : 'cursor:pointer; font-size:80px; font-family:Saira Stencil One, cursive;'"  id="countdown2">
                        <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:8%; margin-top:-8px;'"> {{ countdown }}
                    </h2>
                </div>
                <br>
            </b-col>
            <b-col cols="11" md="5">
                <div class="text-center" id="countdown2" v-if="!isMobile">
                    <h2 style="cursor:pointer; font-size:40px; font-family:Saira Stencil One, cursive;"  id="countdown2">
                        <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:8%; margin-top:-15px;' : ''"> {{ countdown }}
                    </h2>
                </div>
                <div  id="fondoPista" >
                  <b-card  style="background-color:rgba(0, 0, 0, 0); border-color:#187abe;">
                  <br v-if="!isMobile">
                  <b-card-text class="text-center">
                    <template v-if="!loader">
                        <template v-if="correcto == 0">
                              <p class="text-white" style="font-size:25px;" v-if="user.canal == 3">
                                ¿Superar mi cuota me da más puntos para la convención?
                              </p>
                            <p class="text-white" style="font-size:25px;" v-else>
                                Ingresa la respuesta secreta
                              </p>
                              <!-- <p class="text-white" style="font-size:18px;">Cuentas con {{user.pista}} oportunidades.</p> -->
                              <br>
                              <center>
                                  <b-form-input v-model="pista" placeholder="Respuesta"   type="text"  class=" text-center" style="width: 60%;  background: #000; border-color: #5cb617; color:#5cb617;"/>
                                  <br>
                                  <v-btn class="mt-5"    color="#5cb617"   :style="isMobile ? 'width:80%;':'width:25%;'" @click="respondeCodigo()">Enviar <b-spinner v-if="loader" small></b-spinner> </v-btn>
                              </center> 
                        </template>     
                       <template v-else-if="correcto == 1"> 
                              <p class="text-white mt-5" :style="isMobile ? 'font-size:37px;' : 'font-size:40px;'">
                                  <b>Respuesta correcta</b> <br>
                                   <span :style="isMobile ? 'font-size:30px;' : 'font-size:30px;'">¡Ganas <span style="color:#187abe;"><b>15 Puntos!</b></span></span>
                              </p>            
                        </template>                                     
                        <template v-else-if="correcto == 2"> 
                              <p class="text-white mt-5" :style="isMobile ? 'font-size:37px;' : 'font-size:40px;'">
                                  <b style="color:#5cb617;">Respuesta incorrecta</b> <br>
                                   <span :style="isMobile ? 'font-size:30px;' : 'font-size:30px;'">Lo sentimos agotaste todas tus oportunidades</span>
                              </p> 
                        </template>
                        <template v-else-if="correcto == 3"> 
                              <p class="text-white mt-5" :style="isMobile ? 'font-size:37px;' : 'font-size:40px;'">
                                  <b style="color:#5cb617;">Respuesta incorrecta</b> <br>
                                   <span :style="isMobile ? 'font-size:30px;' : 'font-size:30px;'">Tienes una oportunidad más <br><b style="cursor: pointer;" @click="cerrarCodigo()"><u style="color:#5cb617;">¡VAMOS!</u></b></span>
                              </p> 
                        </template>    
                        <template v-else-if="correcto == 5"> 
                              <p class="text-white mt-5" :style="isMobile ? 'font-size:37px;' : 'font-size:40px;'">
                                  <b style="color:#5cb617;">Respuesta incorrecta</b> <br>
                                   <span :style="isMobile ? 'font-size:30px;' : 'font-size:30px;'">Esta Pista ya fue enviada y registrada</span>
                              </p> 
                        </template>
                        <br>
                        <!-- <v-btn v-if="correcto != 0" class="mt-5"    color="#eaeaea"   :style="isMobile ? 'width:80%;':'width:25%;'" @click="cerrarCodigo()">Cerrar </v-btn> -->
                        <div class="text-center mt-5">
                            <p class="text-danger" v-if="error.includes( 'vacio' )"><b>Ingrese respuesta secreta.</b></p>
                        </div>
                    </template>
                    <template v-else>
                        <b-spinner style="color:#fff;" small></b-spinner>
                    </template>
                  </b-card-text>
                  <br v-if="!isMobile">
                 </b-card>
                </div>
            </b-col>
        </b-row>
     </center>
  </div>
</template>

<script>
import * as moment from 'moment';
export default {
  name: 'Pista',
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      pista:"",
      countdown: moment.utc(61).format('HH:mm:ss'),
      expires_in: null,
      interval: null,
      Clickinterval: false,
      error:[],
      total:"",
      correcto:0,
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
  },
  destroyed () {
      clearInterval(this.interval);
  },
  methods:{
    _setInterval: function() {
    // this.mostrarPregunta = 1;
    if(this.Clickinterval==false){
        this.interval = setInterval(() => {
            if (this.expires_in === 0) {
                clearInterval(this.interval);
            } else {
                this.expires_in += 1;
                if(this.expires_in>0){
                    this.countdown = moment.utc(this.expires_in * 1000).subtract(1, 'seconds').format('HH:mm:ss');
                }
            }
        }, 1000);
        this.Clickinterval = true;
     }
    },
    respondeCodigo(){
       this.error = [];
      if (!this.pista) {
          this.error.push('vacio');    
      }else{
       this.loader = true;
       this.$api.post('pista/store', {
            mrt: this.user.mrt,
            idpdv: this.user.id_pdv,
            email: this.user.email,
            seccion: 'Pista8',
            puntos: 15,
            perfil: this.user.perfil,
            respuesta: this.pista,
            tiempo: this.countdown,
            canal: this.user.canal
        }).then(response => {
                console.log(response);
                this.loader = false;  
              
                this.correcto = response.data.valida == 0 ? 2 :  response.data.valida; 
                this.getPista();
            })
            .catch(error => {
                console.log(error);
                this.loader = false;
            });
      }
    },
    cerrarCodigo(){
      this.error = [];
      this.correcto = 0;
      this.countdown = moment.utc(32).format('HH:mm:ss');
      this.expires_in = 1;
      this._setInterval();
    },
    getPista(){
      this.loader = true;
      var url= 'pista/estatus?email='+ this.user.email + '&canal='+ this.user.canal;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);    
                this.loader = false;                  
               if(data.valida == 1){  
                this.correcto = data.valida; 
                clearInterval(this.interval);                
                    this.$confetti.start({
                        particles: [
                            {
                            type: 'rect',
                            },
                            {
                            type: 'circle',
                            },
                        ],
                        defaultColors: [
                            '#5cb617',
                            '#ffffff',
                            '#187abe'
                        ],
                    });
                    setTimeout(() => {
                        this.$confetti.stop();
                    }, 2000);
               }else if(data.valida == 2){
                this.correcto = data.valida; 
                clearInterval(this.interval);
               }
            }
        );
    },
  },
  mounted(){
    this.countdown = moment.utc(32).format('HH:mm:ss');
    this.expires_in = 1;
    this._setInterval();
    this.getPista();
  } 
}
</script>
<style>
 #fondoPista{
     background-image: url('https://www.inquebrantables2022.com.mx/elementos/pista.jpg');
     width: 100%!important;
    }
</style>