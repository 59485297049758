<template>
  <div class="container-fluid">   
     <b-row class="justify-content-center">
        <b-col cols="11" md="9">
            <img src="elementos/logo.png" class="rounded mt-4 float-right" alt="Image" :style="isMobile ? 'width:100%;' : ''">
            <br><br v-if="!isMobile"><br><br>
            <p :class="isMobile ? 'text-white mt-5 text-center' : 'text-white mt-5 text-right'" >
                <img v-if="isMobile"  :src="nav == 0 ? 'elementos/home2.png' : 'elementos/home.png'" :class="!isMobile ? 'rounded  float-right ' :'rounded'" alt="Image" :style="!isMobile ? 'width:2%; cursor:pointer;' : 'width:8%; cursor:pointer; margin-top:-10px;'" @click="cambiarNav(0)">
                <u :class="isMobile ? 'ml-5 mt-5' : 'ml-5 mt-5'" :style="nav == 7 ? 'color:#187abe; cursor: pointer;' : 'color:#5cb617; cursor: pointer;'" @click="cambiarNav(7)">Contacto</u>
                <u :class="isMobile ? 'ml-5 mt-5' : 'ml-5 mt-5'" style="color:#5cb617; cursor: pointer;" @click="cerrarSesion()">Cerrar sesión</u>
                <br>
              <b> <br v-if="isMobile">
                <span style="font-size:15px;"><img v-if="!isMobile"  :src="nav == 0 ? 'elementos/home2.png' : 'elementos/home.png'" :class="!isMobile ? 'rounded' :'rounded'" alt="Image" :style="!isMobile ? 'width:2%; cursor:pointer;  margin-top:-8px;' : 'width:8%; cursor:pointer; margin-top:-10px;'" @click="cambiarNav(0)">
                    &nbsp;&nbsp;     {{user.email}} </span>
                  <span class="text-secondary"  v-if="user.canal == 3">/ Propio</span>
                  <span class="text-secondary"  v-if="user.canal == 2">/ Telemarketing</span>
                  <span class="text-secondary"  v-if="user.canal == 1">/ Retail</span>


              </b>
            </p>
        </b-col>
          
        <template v-if="nav == 0 && isMobile">
            <div class="text-center mt-5" id="countdown2">
                  <h2 :style="isMobile ? 'cursor:pointer; font-size:45px;  font-family:Saira Stencil One, cursive;' : 'cursor:pointer; font-size:160px;  font-family:Saira Stencil One, cursive;'"  id="countdown2">
                    <p id="demo">
                      {{reloj}}
                    </p>
                    <p id="demo2" style="margin-top:-40px;">&nbsp;<span style="font-size:15px;">&nbsp;DÍAS &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; HORAS &nbsp; &nbsp;&nbsp;&nbsp;
                      MINS &nbsp;&nbsp;  SEGS</span></p>
                  </h2>
              </div>
        </template>
        <!-- <b-col cols="12" md="3">
        </b-col> -->
        <!-- <br v-if="isMobile"> -->
        <b-col cols="10" md="9" class="text-center mt-1" :style="isMobile ? 'margin-top:-20px;' : 'margin-top:-20px;'">
            <b-row class="justify-content-center">
               <!-- <b-col cols="12" xl="2" lg="3" class="mt-4" v-if="!isMobile">
                <img :src="nav == 0 ? 'elementos/home2.png' : 'elementos/home.png'" :class="!isMobile ? 'rounded  float-right' :'rounded'" alt="Image" :style="!isMobile ? 'width:15%; cursor:pointer;' : 'width:15%; cursor:pointer;'" @click="cambiarNav(0)">
               </b-col> -->
                <b-col cols="11" md="2" lg="3" xl="2">
                  <template   v-if="isMobile">
                    <a @click="cambiarNav(5)" >
                        <b-alert show variant="primary" :style="nav == 5 ? 'background: #187abe; border-color: #187abe; border-radius: 5px; cursor: pointer;' : 'background: #5cb617; border-color: #5cb617; border-radius: 5px; cursor: pointer;'" id="caja">
                          <b-row class="justify-content-center" style="margin:-8px;">
                            <b-col cols="3">
                              <img src="elementos/user.png" class="rounded  float-left" alt="Image" style="width:80%;">   
                            </b-col>
                            <b-col cols="7" class="mt-3">
                              <h5 style="color:#fff;" class="text-left"><b>Mi perfil</b></h5>
                            </b-col>                      
                          </b-row>
                        </b-alert> 
                      </a>
                    </template>
                    <template v-else>
                      <v-btn :color="nav == 5 ? '#187abe' : '#5cb617'"  id="menus" :class="isMobile ? 'mt-5' : 'mt-2'" block  dark   style="text-transform:capitalize;" @click="cambiarNav(5)" >
                              <img src="elementos/user.png" class="rounded" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:10%;'">  
                              <span class="ml-3">Mi perfil</span>
                        </v-btn>
                    </template>     
               </b-col>
               <b-col cols="11" md="2" lg="3" xl="2">
                <template  v-if="isMobile">
                  <a  @click="cambiarNav(1)" >
                    <b-alert show variant="primary" :style="nav == 1 ? 'background: #187abe; border-color: #187abe; border-radius: 5px; cursor: pointer;' : 'background: #5cb617; border-color: #5cb617; border-radius: 5px; cursor: pointer;'" id="caja">
                      <b-row class="justify-content-center" style="margin:-8px;">
                        <b-col cols="3">
                          <img src="elementos/mision.png" class="rounded float-left" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:70%;'" >
                        </b-col>
                        <b-col cols="7" class="mt-2">
                          <h5 style="color:#fff;" class="text-left"><b>Misión</b></h5>
                        </b-col>                      
                      </b-row>
                    </b-alert> 
                  </a>
                  </template>
                  <template v-else>
                      <v-btn :color="nav == 1 ? '#187abe' : '#5cb617'" id="menus" :class="isMobile ? 'mt-5' : 'mt-2'" block  dark style="text-transform:capitalize;" @click="cambiarNav(1)" >
                          <img src="elementos/mision.png" class="rounded" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:10%;'" >
                          <span class="ml-3">Misión</span>
                      </v-btn>
                  </template>
               </b-col>
               <b-col cols="11" md="2" lg="3" xl="2">
                <template   v-if="isMobile">
                  <a   @click="cambiarNav(2)" >
                    <b-alert show variant="primary" :style="nav == 2 ? 'background: #187abe; border-color: #187abe; border-radius: 5px; cursor: pointer;' : 'background: #5cb617; border-color: #5cb617; border-radius: 5px; cursor: pointer;'" id="caja">
                      <b-row class="justify-content-center" style="margin:-8px;">
                        <b-col cols="3">
                          <img src="elementos/conocimiento.png" class="rounded float-left" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:70%;'">
                        </b-col>
                        <b-col cols="7" class="mt-1">
                          <h5 style="color:#fff;" class="text-left"><b>Conocimientos</b></h5>
                        </b-col>                      
                      </b-row>
                    </b-alert> 
                  </a>
                </template>
                <template v-else>
                  <v-btn :color="nav == 2 ? '#187abe' : '#5cb617'" id="menus" :class="isMobile ? 'mt-5' : 'mt-2'" block dark  style="text-transform:capitalize;"  @click="cambiarNav(2)">
                        <img src="elementos/conocimiento.png" class="rounded" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:15%;'">
                        <span class="ml-3">Conocimientos</span>
                  </v-btn>
                </template>
               </b-col>
               <b-col cols="11" md="2" lg="3" xl="2">
                <template   v-if="isMobile">
                  <a  @click="cambiarNav(3)">
                    <b-alert show variant="primary" :style="nav == 3 ? 'background: #187abe; border-color: #187abe; border-radius: 5px; cursor: pointer;' : 'background: #5cb617; border-color: #5cb617; border-radius: 5px; cursor: pointer;'" id="caja">
                      <b-row class="justify-content-center" style="margin:-8px;">
                        <b-col cols="3">
                          <img src="elementos/entrenamiento.png" class="rounded float-left" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:70%;'">  
                        </b-col>
                        <b-col cols="7" class="mt-1">
                          <h5 style="color:#fff;" class="text-left"><b>Entrenamiento</b></h5>
                        </b-col>                      
                      </b-row>
                    </b-alert> 
                  </a>
                </template>
                <template v-else>
                     <v-btn  :color="nav == 3 ? '#187abe' : '#5cb617'"  id="menus" :class="isMobile ? 'mt-5' : 'mt-2'" block dark   style="text-transform:capitalize;"   @click="cambiarNav(3)">
                            <img src="elementos/entrenamiento.png" class="rounded" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:15%;'">  
                            <span class="ml-3">Entrenamiento</span>
                      </v-btn>
                </template>             
               </b-col>
               <b-col cols="11" md="2" lg="3" xl="2">
                <template  v-if="isMobile">
                <a   @click="cambiarNav(4)" >
                   <b-alert show variant="primary" :style="nav == 4 ? 'background: #187abe; border-color: #187abe; border-radius: 5px; cursor: pointer;' : 'background: #5cb617; border-color: #5cb617; border-radius: 5px; cursor: pointer;'" id="caja">
                    <b-row class="justify-content-center" style="margin:-8px;">
                      <b-col cols="3">
                         <img src="elementos/pistas_negro.png" class="rounded  float-left" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:70%;'">   
                      </b-col>
                      <b-col cols="7" class="mt-1">
                        <h5 style="color:#fff;" class="text-left"><b>Pistas</b></h5>
                      </b-col>                      
                    </b-row>
                  </b-alert> 
                </a>
                </template>
                <template v-else>
                  <v-btn :color="nav == 4 ? '#187abe' : '#5cb617'"  id="menus" :class="isMobile ? 'mt-5' : 'mt-2'" block dark   style="text-transform:capitalize;"   @click="cambiarNav(4)">
                        <img src="elementos/pistas_negro.png" class="rounded" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:10%;'">  
                        <span class="ml-3">Pistas</span>
                  </v-btn>
                </template>
              </b-col>
              <b-col cols="11" md="2" lg="3" xl="2">
                  <template  v-if="isMobile">
                    <a   @click="cambiarNav(6)" >
                      <b-alert show variant="primary" :style="nav == 6 ? 'background: #187abe; border-color: #187abe; border-radius: 5px; cursor: pointer;' : 'background: #5cb617; border-color: #5cb617; border-radius: 5px; cursor: pointer;'" id="caja">
                        <b-row class="justify-content-center" style="margin:-8px;">
                          <b-col cols="3">
                            <img src="elementos/rankings.png" class="rounded  float-left" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:70%;'">   
                          </b-col>
                          <b-col cols="7" class="mt-1">
                            <h5 style="color:#fff;" class="text-left"><b>Ranking</b></h5>
                          </b-col>                      
                        </b-row>
                      </b-alert> 
                    </a>
                    </template>
                    <template v-else>
                      <v-btn :color="nav == 6 ? '#187abe' : '#5cb617'"  id="menus" :class="isMobile ? 'mt-5' : 'mt-2'" block dark   style="text-transform:capitalize;"  @click="$router.push('/ranking')" >
                            <img src="elementos/rankings.png" class="rounded" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:10%;'">  
                            <span class="ml-3">Ranking</span>
                      </v-btn>
                    </template>
                </b-col>
            </b-row>
        </b-col>
      </b-row>
     <div id="menoabajo"></div>
     <template v-if="nav == 1">
      <mision></mision>
     </template>
     <template v-else-if="nav == 2">
      <conocimiento></conocimiento>
     </template>
     <template v-else-if="nav == 3">
      <juego2></juego2>
      <!-- <new2></new2> -->
     </template>
     <template v-else-if="nav == 4">
      <pista></pista>
     </template>
     <template v-else-if="nav == 5">
       <perfil></perfil>
     </template>
     <template v-else-if="nav == 6">
       <!-- <Ranking></Ranking> -->
     </template>
     <template v-else-if="nav == 7">
       <Contacto></Contacto>
     </template>
      <template v-if="nav == 0 && !isMobile">
        <br v-if="!isMobile"><br v-if="!isMobile">
           <div class="text-center">
                <h2 :style="isMobile ? 'cursor:pointer; font-size:45px;  font-family:Saira Stencil One, cursive;' : 'cursor:pointer; font-size:138px;  font-family:Saira Stencil One, cursive; margin-top:-60px;'" >
                  <p id="demo">{{reloj}}</p>
                  <p id="demo2" style="margin-top:-120px;">&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size:40px;">&nbsp;DÍAS &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    HORAS &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MINS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                    SEGS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                </h2>
            </div>
      </template>


    <b-modal id="actualizaMRT" title=" " hide-footer hide-header no-close-on-backdrop :hideHeaderClose="true" :cancelDisabled="true" body-bg-variant="dark">
        <div class="text-center text-white" >            
            <h4 class="mt-4" style="color:#5cb617;" v-if="enviado == 0">Importante</h4>            
            <h4 class="mt-4" style="color:#5cb617;" v-else>Aviso</h4>         
            <p class="mt-4 text-white" v-if="enviado == 0 && confirma == 0">Para continuar con el ingreso <br> comparte tu AXC/MRT/MRN</p>
            <p class="mt-4 text-white" v-else-if="enviado == 1 && confirma == 0">Tu información se ha enviado exitosamente.</p>
            <b-row class="justify-content-center">
              <b-col cols="8" v-if="enviado == 0">
                <template v-if="confirma == 0">
                  <b-form-input v-model="mrt" placeholder="AXC/MRT/MRN"   type="text"  class=" text-center mt-4" />
                  <div class="text-center">
                    <p class="text-danger mt-2" v-if="errors.includes( 'mrt' )"><b>Ingresa tu AXC/MRT/MRN.</b></p>
                    <p class="text-danger mt-2" v-if="errors.includes( 'falso' )"><b>AXC/MRT/MRN Incorrecto. <br> favor de verificar.</b></p>
                  </div>
                </template>
                <template v-else>
                  <p class="mt-4 text-white">¿Es este tu AXC/MRT/MRN?.</p>
                  <b-form-input v-model="mrt" placeholder="AXC/MRT/MRN"   type="text"  class=" text-center mt-4" />
                  <b-row class="justify-content-center">
                    <b-col cols="4">
                       <v-btn  color="#5cb617"  class="mt-3"  dark style="text-transform:capitalize; width: 50%;" @click="bajarModal()">
                          SI
                       </v-btn>
                    </b-col>
                    <b-col cols="4">
                       <v-btn  color="#187abe"  class="mt-3"  dark style="text-transform:capitalize; width: 50%;" @click="confirma = 0">
                          NO
                       </v-btn>
                    </b-col>
                  </b-row>

                </template>
                <v-btn  color="#5cb617"  class="mt-3"  dark style="text-transform:capitalize; width: 50%;" @click="enviarMRT()" v-if="confirma == 0">
                  Enviar <b-spinner v-if="loader" small></b-spinner> 
                </v-btn>
                <br>
              </b-col>
              <b-col cols="8" v-else>
                <v-btn  color="#5cb617"  class="mt-3"  dark style="text-transform:capitalize; width: 50%;" @click="bajarModal()">
                  OK
                </v-btn>
                <br>
              </b-col>
            </b-row>
            <br>
        </div>
    </b-modal>

  </div>
</template>

<script>

// import $ from "jquery";
import Cookies from "js-cookie";
import conocimiento from "./Conocimiento.vue";
import mision from "./Mision.vue";
import pista from "./Pista.vue";
import perfil from "./Perfil.vue";
import * as moment from 'moment';
import juego2 from "./Juego2"
import Contacto from './Contacto.vue';
// // import Ranking from '../Ranking/Ranking.vue';
// import new2 from "./New"
export default {
  name: 'Home',
  components: {
    conocimiento,
    mision,
    pista,
    perfil,
    Contacto,
    juego2,
    // Ranking,
    // new2
  },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      nav:0,
      countdown: moment.utc(61).format('DD:HH:mm:ss'),
      expires_in: null,
      interval: null,
      Clickinterval: false,
      reloj: "00.00.00.00",
      mrt:"",
      errors:[],
      enviado:0,
      actualiza:0,
      confirma:0
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
  methods:{
    cambiarNav(id){
       this.nav = id;
      if (this.isMobile)
        {
          window.scrollTo(0, 700);
        }       
    },
    aceptaBienvenida(){
      this.loader = true;
      var url= 'auth/bienvenida?id='+ this.user.id;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.loader = false;
                this.$router.push("/");       
            }
        );
    },
    cerrarSesion(){
        this.$store.commit(
            "setLoginStatus",
            false
        );
        this.$store.commit(
            "setInfoUser",
            null
        );    
        Cookies.remove("session_id");
        location.reload();
    },
    // setInterval: function() {
        // this.interval = setInterval(() => {
            // var countDownDate = new Date("Oct 24, 2022 10:00:00").getTime();

            //         // Get today's date and time
            //   var now = new Date().getTime();
                
              // Find the distance between now and the count down date
              // var distance = countDownDate - now;

            
                
            // Time calculations for days, hours, minutes and seconds
            // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            // var ceroD = days < 10 ? '0' + days : days;
            // var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            // var ceroH = hours < 10 ? '0' + hours : hours;
            // var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            // var ceroM = minutes < 10 ? '0' + minutes : minutes;
            // var seconds =  Math.floor((distance % (1000 * 60)) / 1000);
            // var ceroS = seconds < 10 ? '0' + seconds : seconds ;
            // Output the result in an element with id="demo"
            // this.reloj = 00 + "." + 00 + "." + 00 + "." +  00;         

        //  }, 1000);
    // },
    enviarMRT(){
      this.errors = [];
      if (!this.mrt) {
        this.errors.push('mrt');   
      }
      if(this.mrt.includes("AXC") == false && this.mrt.includes("MRT") == false && this.mrt.includes("MRN") == false){
        this.errors.push('falso');   
      }else{
        this.loader = true;
        var url= 'home/actualiza?id='+ this.user.id + '&mrt='+ this.mrt;
            this.$api.get(url).then(
                ({data}) => {
                    console.log(data);
                    this.loader = false;
                    this.enviado = 1;                   
                }
            );
      }
    },
    bajarModal(){
      this.enviado = 0;
      this.$bvModal.hide('actualizaMRT');
    },
    traeAct(){
      var url= 'home/trae?id='+ this.user.id;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                if (this.user.perfil == 'Ejecutivo')
                  {
                    if (!data.actualiza)
                    {
                      this.confirma = 0;
                    }else{
                      this.confirma = 1;
                      this.mrt = data.actualiza;
                    }

                    this.$bvModal.show('actualizaMRT');
                  }     
            }
        );
    }
  },
  mounted(){
    // this.setInterval();
    this.traeAct();


  }
}

</script>
<style >
#demo{
   -webkit-text-stroke: 2px #5cb617;
  color: #5cb617; 
}
#demo2{
   -webkit-text-stroke: 0.1px #5cb617;
  color: #5cb617; 
}

@media only screen and (min-width: 1000px) 
{
        #menus
        {
            height: 30%;
            font-size: 12px;
            width: 110%;
            margin: 90px;
            margin-left: 100px;
        }
}
@media only screen and (min-width: 1300px) 
{
        #menus
        {
            height: 30%;
            font-size: 12px;
            width: 115%;
            margin: 80px;
            margin-left: 58px;
        }
}
@media only screen and (min-width: 1400px)
{
        #menus
        {
            height: 30%;
            font-size: 15px;
            width: 110%;
            margin: 80px;
            margin-left: 38px;
        }
}
@media only screen and (min-width: 1600px)
{
        #menus
        {
            height: 30%;
            font-size: 15px;
            width: 110%;
            margin: 80px;
            margin-left: 18px;
        }
}
@media only screen and  (min-width: 1800px)
{
        #menus
        {
            height: 35%;
            font-size: 15px;
            width: 100%;
            /* margin: 80px; */
            margin-left: -13px;
        }
}

</style>