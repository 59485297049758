<template>
  <div>
    <center>
   <br>
    <b-row class="justify-content-center mt-2">
         <b-col cols="10" md="5">
            <p class="text-info" :style="isMobile ? 'font-size:15px;' : 'font-size:20px;'">
              <b>
                Sabemos que cuentas con todas las aptitudes para ser un agente inquebrantable, durante la misión estamos evaluando el trabajo en equipo, el conocimiento
                del negocio y especialmente el cumplimiento constante de las cuotas comerciales.

                <br><br>

                Tu esfuerzo y dedicación te harán  parte de este grupo de élite. <br>
                La misión ha comenzado.
               </b>
            </p>
            <br>
            <!-- <img src="elementos/flecha.png" class="rounded" alt="Image" :style="isMobile ? 'width:10%;' : 'width:5%;'">
            <br><br> -->
            <a href="bases/MisionPropio.pdf" style="text-decoration:none;"  download target="_blank" v-if="user.perfil == 'Ejecutivo' || user.perfil == 'Gerente' || user.perfil == 'Coordinador' ">
                <v-btn  color="#5cb617"  class="mt-2"   x-large  dark style="text-transform:capitalize;" >
                    Misión
                </v-btn>
            </a>
            <a href="bases/MisionRetailLider.pdf" style="text-decoration:none;" download target="_blank" v-if=" user.perfil == 'Lider' || user.perfil == 'Genérico' ">
                <v-btn  color="#5cb617"  class="mt-2"   x-large  dark style="text-transform:capitalize;" >
                    Misión
                </v-btn>
            </a>
            <a href="bases/MisionAgente.pdf" style="text-decoration:none;" download target="_blank" v-if=" user.perfil == 'Agente' ">
                <v-btn color="#5cb617"  class="mt-2"   x-large  dark style="text-transform:capitalize;" >
                    Misión
                </v-btn>
            </a>
            <a href="bases/MisionTlmkt.pdf" style="text-decoration:none;" download target="_blank" v-if=" user.perfil == 'TLMKT' ">
                <v-btn  color="#5cb617"  class="mt-2"   x-large  dark style="text-transform:capitalize;" >
                   Misión
                </v-btn>
            </a>
          </b-col>
        </b-row>
     </center>
  </div>
</template>

<script>
export default {
  name: 'Inicio',
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
  },
  methods:{
    aceptaBienvenida(){
      this.loader = true;
      var url= 'home/bienvenida?id='+ this.user.id;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.loader = false;
                this.$router.push("/");       
            }
        );
    }
  }
}
</script>