<template>
  <div>
    <center>
    <b-row class="justify-content-center">
         <b-col cols="10">
            <img  src="elementos/franja.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image"   :style="!isMobile ? 'margin-top:15px; margin-left:-300px;' : 'width:110%;'">
         </b-col>
         <!-- <b-col cols="2"></b-col> -->
         <!-- <b-col cols="10" md="2" class="mt-5" :style="!isMobile ? 'margin-left:-70px;' : ''">
              <img v-if="user.canal == 3"  src="elementos/propio.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image"   :style="!isMobile ? 'width:35%; margin-top:-5px;' : 'width:30%;'">
              <img v-if="user.canal == 2"  src="elementos/tele.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image"   :style="!isMobile ? 'width:35%; margin-top:-5px;' : 'width:30%;'">
              <img v-if="user.canal == 1"  src="elementos/retail.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image"   :style="!isMobile ? 'width:35%; margin-top:-5px;' : 'width:30%;'">
         </b-col> -->
         <b-col cols="10" md="4" class="mt-5" >
              <!-- <b-card  style="background-color:rgba(0, 0, 0, 0); border-color:#187abe;">
                  <b-card-text class="text-center"> -->
              <!-- <img  src="elementos/propio.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image"   :style="!isMobile ? 'width:15%; margin-top:15px;' : 'width:100%;'"> -->
                    <b-row class="justify-content-center">
                         <b-col cols="4" md="2" :class="isMobile ? 'text-center mt-2' : 'text-right mt-2'">
                           <img v-if="user.canal == 3" src="elementos/propio.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image" :style="!isMobile ? 'width:100%;  margin-top:-8px; margin-right:-20px;' : 'width:100%;'">
                           <img v-if="user.canal == 2" src="elementos/tele.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image" :style="!isMobile ? 'width:100%;  margin-top:-8px; margin-right:-20px;' : 'width:100%;'">
                           <img v-if="user.canal == 1" src="elementos/retail.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image" :style="!isMobile ? 'width:100%;  margin-top:-8px; margin-right:-20px;' : 'width:100%;'">
                         </b-col> 
                          <b-col cols="12" md="8" :class="isMobile ? 'text-center mt-2' : 'text-left mt-1'">
                                <h4 style="color:#fff;">
                                    <span :class="isMobile ? 'mt-4' :'ml-4'">{{user.perfil}}</span>                                   
                                </h4>
                                <p :style="isMobile ? 'color:#fff;' : 'color:#fff; margin-left:20px;'"> {{user.nombre}}</p>
                                <p v-if="user.canal == 3" :style="isMobile ? 'color:#fff;' : 'color:#fff; margin-left:20px; margin-top:-15px;'"> Canal Propio</p>
                                <p v-if="user.canal == 2" :style="isMobile ? 'color:#fff;' : 'color:#fff; margin-left:20px; margin-top:-15px;'"> Canal Telemarketing</p>
                                <p v-if="user.canal == 1" :style="isMobile ? 'color:#fff;' : 'color:#fff; margin-left:20px; margin-top:-15px;'"> Canal Retail</p>
                          </b-col>
                    </b-row>
                        <v-progress-circular
                            :rotate="360"
                            :size="150"
                            :width="20"
                            :value="puntos"
                            color="#187abe"
                            elevation="13"
                            class="mt-2"
                        >
                        <span style="color:#fff; font-size:40px;">{{puntos}}</span>
                         <br>
                         <span class="ml-2" style="color:#fff; font-size:15px;"> Pts.</span>
                        </v-progress-circular>
                        <b-row class="justify-content-center mt-2">
                         <b-col cols="11" md="9">
                          <h5 style="color:#5cb617;">{{ranking}}° Lugar</h5>
                          <hr>
                            <b-progress class="mt-2" :max="max" show-value  height="2rem" >
                                <b-progress-bar :value="value * (4 / 10)" style="background:#5cb617; color:#000; font-size:14px;">Mayo</b-progress-bar>
                                <b-progress-bar :value="value * (4 / 10)" style="background:#5cb617; color:#000; font-size:14px;">Junio</b-progress-bar>
                                <b-progress-bar :value="value * (4 / 10)" style="background:#5cb617; color:#000; font-size:14px;">Julio</b-progress-bar>
                                <!-- <b-progress-bar :value="value * (6 / 10)" style="background:#b1b3b7; color:#000; font-size:18px;">Agost</b-progress-bar>
                                <b-progress-bar :value="value * (6 / 10)" style="background:#b1b3b7; color:#000; font-size:18px;">Sept</b-progress-bar>
                                <b-progress-bar :value="value * (6 / 10)" style="background:#b1b3b7; color:#000; font-size:18px;">Oct</b-progress-bar>
                                <b-progress-bar :value="value * (6 / 10)" style="background:#b1b3b7; color:#000; font-size:18px;">Nov</b-progress-bar> -->
                            </b-progress>
                         </b-col>
                        </b-row>
                  <!-- </b-card-text>
               </b-card> -->
          </b-col>
          <b-col cols="11">
            <br>
             <img  src="elementos/franja2.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image"   :style="!isMobile ? 'margin-top:25px; margin-left:350px;' : 'width:100%;'">
            <br>
         </b-col>
        </b-row>
     </center>
  </div>
</template>

<script>
export default {
  name: 'Inicio',
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      puntos:0,
         value: 45,
        max: 100,
      ranking:0
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
  },
  methods:{
    traePuntos(){
      this.loader = true;
      var url= 'puntos/index?id='+ this.user.email;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.puntos = data.puntos;
                this.ranking = data.ranking;
                this.loader = false;      
            }
        );
    }
  },
  mounted(){
    this.traePuntos();
  }
}
</script>
<style >
.v-progress-circular {
  margin: 1rem;}
</style>