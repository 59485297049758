<template>
  <div>   
    <br v-if="isMobile">
     <b-row class="justify-content-center mt-5">
        <b-col cols="12" md="4" v-if="isMobile">
            <div class="text-center mt-4" id="countdown2" >
                <h2 :style="isMobile ? 'cursor:pointer; font-size:30px; font-family:Saira Stencil One, cursive;' : 'cursor:pointer; font-size:80px; font-family:Saira Stencil One, cursive; '"  id="countdown2">
                    <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:15%;' : 'width:8%; margin-top:-8px;'"> {{ countdown }}
                </h2>
            </div>
            <br>
        </b-col>
        <b-col cols="12" md="5">          
            <div class="text-center" id="countdown2" v-if="!isMobile">
                <h2 style="cursor:pointer; font-size:40px; font-family:Saira Stencil One, cursive;"  id="countdown2">
                    <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:8%; margin-top:-15px;' : ''"> {{ countdown }}
                </h2>
            </div>
            <b-card  style="background-color:rgba(0, 0, 0, 0); border-color:#187abe;" class="mt-3">
                <br v-if="!isMobile">
                <b-card-text class="text-center">
                    <template v-if="correcto == 0">
                        <p class="text-white" :style="isMobile ? 'font-size:18px;' : 'font-size:20px;'" v-if="pregunta != ''">{{pregunta}}</p>
                        <b-row class="justify-content-center mt-5">
                            <center>
                                <template v-if="respuestas.length > 0"> 
                                    <div v-for="res in respuestas" :key="res.id">
                                        <b-col cols="12" md="8">
                                            <b-row class="justify-content-center">
                                                <b-col cols="11" md="8">
                                                    <v-btn block dark color="#5cb617" :style="isMobile ? 'text-transform:none; height: 80%; font-size:12px;' : 'text-transform:none; height: 80%;'"  @click="respondePregunta(res)">
                                                        {{res.respuesta}} 
                                                        <template v-if="res.id == 10 || res.id == 11">
                                                            <br>
                                                            {{res.canal}}
                                                        </template>
                                                         <b-spinner v-if="loader == res.id" small></b-spinner> 
                                                    </v-btn>  
                                                    <br><br>
                                                </b-col>
                                                <b-col cols="1" md="2" class="mt-3 text-right" v-if="aviso !=  false">
                                                    <transition name="bounce">
                                                        <v-icon  color="#187abe" v-if="res.correcta == 1">
                                                            mdi-checkbox-marked-circle-outline
                                                        </v-icon>
                                                        <v-icon  color="#797b7e" v-else>
                                                           mdi-close-circle
                                                        </v-icon>
                                                    </transition>
                                                </b-col>
                                            </b-row>
                                        </b-col> 
                                    </div>
                                </template>
                                <template v-else>
                                     <b-spinner v-if="loader" small></b-spinner>
                                </template>
                            </center> 
                        </b-row>    
                     </template>   
                     <template v-else-if="correcto == 1">
                        <template v-if="total == 0">
                          <p class="text-white" :style="isMobile ? 'font-size:32px;' : 'font-size:40px;'">
                                <span :style="isMobile ? 'font-size:16px;' : 'font-size:20px;'">Contestaste  
                                <template v-if="total == 5">1 </template>
                                <template v-else-if="total == 10">2 </template>
                                <template v-else-if="total == 20">3 </template>
                                <template v-else>0 </template>
                                de 3 preguntas.</span>
                                <br> Gracias por participar
                                <br>
                                 <span :style="isMobile ? 'font-size:15px;' : 'font-size:20px;'">prepárate mejor para la próxima misión </span>
                            </p>
                        </template>
                        <template v-else-if="total == 5 || total  == 10">
                            <p class="text-white" :style="isMobile ? 'font-size:32px;' : 'font-size:40px;'">
                                <span :style="isMobile ? 'font-size:16px;' : 'font-size:20px;'">Contestaste  
                                <template v-if="total == 5">1 </template>
                                <template v-else-if="total == 10">2 </template>
                                <template v-else-if="total == 20">3 </template>
                                <template v-else>0 </template>
                                de 3 preguntas.</span>
                                <br> ¡Ganaste <span style="color:#187abe;"><b>{{total}} Puntos!</b></span>
                                <br>                             
                            </p>
                        </template>
                        <template v-else-if="total == 20">
                            <p class="text-white" :style="isMobile ? 'font-size:32px;' : 'font-size:40px;'">
                                ¡Felicidades! <br>
                                <span :style="isMobile ? 'font-size:15px;' : 'font-size:20px;'">Contestaste correctamente la trivia.</span>
                                <br> ¡Ganaste <span style="color:#187abe;"><b>{{total}} Puntos</b>!</span>
                            </p>
                        </template>                
                    </template> 
                </b-card-text>
                <br v-if="!isMobile">
            </b-card>
        </b-col>
     </b-row>
  </div>
</template>

<script>
import * as moment from 'moment';

export default {
  name: 'Conocimiento',
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      pregunta:"",
      respuestas:[],
      countdown: moment.utc(61).format('HH:mm:ss'),
      expires_in: null,
      interval: null,
      Clickinterval: false,
      correcto:0,
      total:0,
      aviso:false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
  },
    destroyed () {
        clearInterval(this.interval);
    },
  methods:{
    _setInterval: function() {
        // this.mostrarPregunta = 1;
        if(this.Clickinterval==false){
            this.interval = setInterval(() => {
                if (this.expires_in === 0) {
                    clearInterval(this.interval);
                } else {
                    this.expires_in += 1;
                    if(this.expires_in>0){
                        this.countdown = moment.utc(this.expires_in * 1000).subtract(1, 'seconds').format('HH:mm:ss');
                    }else{
                        this.getTrivia();
                    }
                }
            }, 1000);
            this.Clickinterval = true;
        }
    },
    getTrivia(){
      this.loader = true;
      var url= 'trivia/pregunta?email='+ this.user.email;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.pregunta = data.pregunta;
                this.respuestas = data.respuesta;     
                this.correcto = data.correcto;   
                this.total =  data.total;  
                if (data.correcto == 1)
                {
                    clearInterval(this.interval);    
                    if (data.total != 0)
                    {
                        this.$confetti.start({
                            particles: [
                                {
                                type: 'rect',
                                },
                                {
                                type: 'circle',
                                },
                            ],
                            defaultColors: [
                                '#5cb617',
                                '#ffffff',
                                '#187abe'
                            ],
                        });
                        setTimeout(() => {
                            this.$confetti.stop();
                        }, 2000);
                    }
                }
                this.loader = false;
            }
        );
    },
    respondePregunta(item){

                this.aviso = item.id;
       
       
      
       setTimeout(() => {
         this.loader = item.id
        this.aviso = false;
        this.$api.post('trivia/store', {
                mrt: this.user.mrt,
                idpdv: this.user.id_pdv,
                email: this.user.email,
                seccion: 'Trivia2',
                puntos: 5,
                perfil: this.user.perfil,
                respuesta: item.id,
                tiempo: this.countdown
            }).then(response => {
                    console.log(response);
                    this.getTrivia();
                    this.loader = false;  
                    // this.correcto  = response.data.valida == 0 ? 1: 2;
                })
                .catch(error => {
                    console.log(error);
                    this.loader = false;
                });
        }, 3000)
    }
  },
  mounted(){
    this.countdown = moment.utc(32).format('HH:mm:ss');
    this.expires_in = 1;
    this._setInterval();
    this.getTrivia();
  }
}
</script>
<style >
#countdown2{
  -webkit-text-stroke: 2px #5cb617;
  color: #5cb617; 
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>